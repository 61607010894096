import { ThemeProvider } from '@emotion/react';
import { Autocomplete, Button, createTheme, IconButton, Skeleton, TextField } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Api, ApiContext } from './api';
import styles from './App.module.css'
import logo from './assets/logo.png';
import art from './assets/art.svg'
import { useSnackbar } from 'notistack';
import Fuse from 'fuse.js'

type AnzscoOccupations = { 
  [code: string]: string 
};

type SearchResult = Array<{
  code: number,
  name: string,
  list_type: string,
  info: string,
  li_title: string,
  li_url: string,
}>

const theme = createTheme({
  palette: {
    primary: {
      main: '#4d40ff'
    },
    secondary: {
      main: '#e21e51'
    }
  }
})

function App() {
  const [ anzscoOccupations, setAnzscoOccupations ] = useState<AnzscoOccupations>({})
  const [ searchTerm , setSearchTerm ] = useState<string>('')
  const [ occupationTitle, setOccupationTitle ] = useState<string>('');
  const [ searchResult, setSearchResult ] = useState<SearchResult | null>(null);
  const [ autoCompleteOptions, setAutoCompleteOptions ] = useState<Array<string>>([])
  // Whether waiting for response
  const [ searching, setSearching ] = useState(false);
  
  const { enqueueSnackbar } = useSnackbar();
  
  const mounted = useRef(false);
  
  const api = useMemo(() => new Api(process.env.REACT_APP_ENDPOINT!), []);
  
  /**
    Get occupation list on load
  */
  useEffect(() => {
    mounted.current = true;
    const getOccupations = async () => {
      const resp = await api.occupations();
      if (resp.status === 200 && mounted.current) {
        const respJson = await resp.json();
        // Convert array to object
        const occMap: any = {};
        respJson.forEach((r: any) => occMap[r.anzsco] = r.name);
        setAnzscoOccupations(occMap);
      } else {
        enqueueSnackbar('Unable to connect to server', { variant: 'error' })
      }
    }
    getOccupations()
    return () => { mounted.current = false }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const searchToAnzscoCode = (search: string): string | null => {
    const code = Object.keys(anzscoOccupations).find(code => anzscoOccupations[code] === search);
    return code === undefined ? null : code;
  }
  
  const onSearch = () => {
    const getVisas = async (code: string) => {
      setSearching(true);
      const resp = await api.searchVisas(code);
      const respJson = await resp.json();
      if (resp.status === 200 && mounted.current) {
        setSearching(false);
        setSearchResult(respJson);
      }
    }
    
    // Only search if the code is valid
    // Use fuzzy search
    const closest = (new Fuse(Object.values(anzscoOccupations))).search(searchTerm);
    if (closest.length !== 0) {
      const occTitle = (closest as any)[0].item
      const code = searchToAnzscoCode(occTitle);
      if (code !== null) {
        setOccupationTitle(occTitle);
        getVisas(code);
      }
    } else {
      enqueueSnackbar(`Occupation ${searchTerm} doesnt exist`, { variant: 'error' })
    }
  }
  
  useEffect(() => {
    const result = (new Fuse(Object.values(anzscoOccupations,), { threshold: 0.2 })).search(searchTerm)
    setAutoCompleteOptions(result.map(r => r.item));
  }, [searchTerm, anzscoOccupations])
  
  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={api}>
        <div className={styles.wrapper}>
        
          { /* Page Header*/ }
          <div className={styles.header}>
            <img 
              onClick = { () => window.open('https://hillsmigration.com.au/', '_blank') }
              src={logo} className={styles.logo} alt='logo'/>
          </div>
          
          { /* Page Panel*/ }
          <div className={styles.panel}>
            <div className={styles.panelTitle}>
              Job Visa Search
            </div>
            <img className={styles.panelArt} src={art} alt='woman searching'/>
          </div>
          
          
          { /* Main content*/ }
          <div className={styles.content}>
            <div className={styles.searchWidget}
            >
              <div className={styles.title}>
                Seach your occupation
              </div>
              <div
                className={styles.fieldWrapper}
              >
                <Autocomplete
                  className={styles.materialField}
                  inputValue={searchTerm}
                  onInputChange={(e, v) => setSearchTerm(v)}
                  freeSolo={true}
                  options = {autoCompleteOptions}
                  filterOptions={(x) => x}
                  renderInput = {
                    (params) => <TextField {...params}/>
                  }
                />
              </div>
              <div
                className={styles.fieldWrapper}
              >
                <Button
                  className={styles.materialField}
                  color={'primary'}
                  variant="contained"
                  onClick={onSearch}
                >
                  Search
                </Button>
              </div>
              <div className={styles.disclaimer}>
                We do not guarantee the accuracy of the data.
                Use at your own discretion
              </div>
            </div>
          </div>
          
          { /* Search Results */ }
          {
            searchResult !== null || searching
            ?
            <div className={styles.results}>
              <div className={styles.title}>
                Results
              </div>
              <div className={styles.resultOccupationPanel}>
                
                <div className={styles.resultName}>
                  { 
                    !searching && searchResult !== null
                    ?
                    occupationTitle
                    :
                    <Skeleton variant='text' style={{ width: '100%' }}/>
                  }
                </div> 
                
                <div className={styles.resultCode}>
                  {
                    !searching && searchResult !== null 
                    ?
                    `ANZSCO Code: ${ searchToAnzscoCode(occupationTitle) }`
                    :
                    <Skeleton height='2rem' variant='text' style={{ width: '50%' }}/>
                  }
                </div>
              </div>
              
              {
                searchResult !== null
                ?
                <React.Fragment>
                  <div className={styles.subTitle}>
                    Eligible Visas
                  </div>
                  <div className={styles.visaList}>
                    {
                      searchResult.map(v => (
                        <div key={`${v.code} ${v.name}`} className={styles.visa}>
                          <div className={styles.visaName}>
                            Subclass {v.code} {v.name}
                          </div>
                          <div className={styles.visaListType}>
                            { v.list_type }
                          </div>
                          <div className={styles.visaInfo}>
                            { v.info }
                          </div>
                          <a href={v.li_url} target="_blank" rel="noopener noreferrer">
                            { v.li_title.split(':')[0] }
                          </a>
                        </div>))
                    }
                  </div> 
                </React.Fragment>
                :
                null
              
              }
            </div>
            :
            null
          }
          
          <div className={styles.footer}>
            <div className={styles.footerName}>
              Hills Migration <span className={styles.highlight}> © 2022</span>
            </div>
          </div>
        </div>
      </ApiContext.Provider>
    </ThemeProvider>
  );
}

export default App;
