import { createContext, useContext } from "react";

export const ApiContext = createContext<null | Api>(null);

export class Api {
  private ENDPOINT: string
  
  constructor(ENDPOINT: string) {
    this.ENDPOINT = ENDPOINT;
  }
  
  occupations() {
    return fetch(`${this.ENDPOINT}/api/occupations`)
  }
  
  searchVisas(anzsco: string) {
    return fetch(`${this.ENDPOINT}/api/search-visas?anzsco=${anzsco}`)
  }
}

export const useApi = (): Api => {
  return useContext(ApiContext)!;
}